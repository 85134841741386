@tailwind base;

@layer base {
  button:focus {
    outline: none;
  }
}

@tailwind components;
@tailwind utilities;
